<template>
    <div class="textarea-container">
        <div class="real-textarea">
            <div class="title">
                <img :src="tag"/>
                {{title}}
            </div>
            <textarea @input="onTextareaInput" @blur="onTextareaBlur" :placeholder="placeholder"
                      v-model="value"></textarea>
        </div>
        <div class="disable-div" v-if="disabled"></div>
        <div class="number-limit" v-if="maxNumber > 0">{{value.length}} / {{maxNumber}}</div>
    </div>
</template>

<script>
  export default {
    name: 'services-textarea',
    props: {
      tag: {
        type: String,
        default: 'https://api.mengjingloulan.com/storage/wechat/services/tag.png'
      },
      title: {
        type: String,
        default: '商品标题：'
      },
      placeholder: {
        type: String,
        default: '请输入您的商品标题（40字内）'
      },
      maxNumber: {
        type: [String, Number],
        default: 0
      },
      defaultValue: {
        type: [String, Number],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        value: '',
      }
    },
    mounted () {
      this.value = this.$props.defaultValue
    },
    methods: {
      onTextareaInput (e) {
        this.resetValue(e)
      },
      onTextareaBlur (e) {
        this.resetValue(e)
        this.$emit('onTextareaBlur')
      },
      resetValue (e) {
        let value = e.target._value
        let maxNumber = parseInt(this.$props.maxNumber)
        if (maxNumber > 0) {
          if (value.length > maxNumber) {
            value = value.substr(0, maxNumber)
            this.value = value
            this.$forceUpdate()
          }
        }
      },
      getValue () {
        return this.value
      }
    }
  }
</script>

<style scoped lang="scss">
    .textarea-container {
        display: flex;
        border: #EBEBEB solid 1px;
        padding: 19px 11px;
        height: 126px;
        position: relative;

        .disable-div {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .real-textarea {
            width: 100%;
            height: 100%;
            position: relative;
            background: #fff;

            .title {
                position: absolute;
                left: 0;
                top: -2px;
                font-size: 14px;
                color: #333333;
                display: flex;
                align-items: center;
                width: 100px;
                background: #fff;
                padding-top: 4px;
                z-index: 99;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }
            }

            textarea {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                right: 0;
                bottom: 5px;
                border: none;
                outline: none;
                font-size: 14px;
                color: #939393;
                text-indent: 100px;
                line-height: 20px;
            }

            textarea::placeholder {
                color: #939393;
            }
        }

        .number-limit {
            position: absolute;
            right: 5px;
            bottom: 5px;
            font-size: 14px;
            color: #939393;
        }
    }
</style>