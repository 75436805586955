<template>
    <nut-popup v-model="visible" get-container="body" position="right" :closeable="true" class="village-container">
        <div class="button" @click="chooseAll" v-if="showChooseAll">全部</div>
        <nut-elevator
                :dataArray="dataList"
                :showIndicator="true"
                :navHeight="25"
                :otherHeight="100"
                :initIndex="0"
                :hiddenTime='500'
                @clickNav="clickNav"
                @clickList="clickList"
        >
        </nut-elevator>
    </nut-popup>
</template>

<script>
  export default {
    name: 'village-choose',
    props: {
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      showChooseAll:{
        type:Boolean,
        default:false
      }
    },
    mounted () {
      this.dataList = this.$props.options
    },
    data () {
      return {
        visible: false,
        dataList: []
      }
    },
    methods: {
      show () {
        this.visible = true
      },
      hide () {
        this.visible = false
      },
      clickNav () {

      },
      clickList (e) {
        this.$emit('chooseVillage',e)
        this.hide()
      },
      chooseAll(){
        this.$emit('chooseVillage',{
          value:0,
          name:'全部'
        })
        this.hide()
      }
    }
  }
</script>

<style scoped lang="scss">
    .village-container{
        width: 100%;
        height: 100%;
        padding: 50px 0 0 0;
    }
    /deep/.nut-elevator-nav{
        top: 50%
    }
    
    .button{
        position: absolute;
        left: 18px;
        top: 10px;
        width: 80px;
        height: 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        color: #fff;
    }
</style>