<template>
    <div class="service-village-select-container">
        <div class="title">
            {{title}}
        </div>
        <div class="select-show" @click="show">
            <div class="select-show-text" :class="[value == 0 ? 'placeholder' : '']">{{showText}}</div>
            <img src="https://api.mengjingloulan.com/storage/wechat/services/down-gray.png"/>
        </div>
        <div class="disable-div" v-if="disabled"></div>
        <village-choose ref="village" :options="options" @chooseVillage="chooseVillage"></village-choose>
    </div>
</template>

<script>
  import VillageChoose from '@/pages/services/components/village-choose'

  export default {
    name: 'services-village-select',
    components: {VillageChoose},
    props: {
      title: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      defaultValue: {
        type: Number,
        default: 0
      }
    },
    mounted () {
      if (this.$props.defaultValue) {
        let string = ''
        this.value = this.$props.defaultValue
        this.$props.options.forEach(item => {
          item.list.forEach(val => {
            if (val.value == this.$props.defaultValue) {
              string = val.name
            }
          })
        })
        this.showText = string
      } else {
        this.showText = this.$props.placeholder
      }
    },
    data () {
      return {
        value: 0,
        showText: ''
      }
    },
    methods: {
      show () {
        this.$refs.village.show()
      },
      hide () {
        this.$refs.village.hide()
      },
      chooseVillage (e) {
        this.value = e.value
        this.showText = e.name
        this.$emit('onChooseVillage',e.value)
      },
      getValue () {
        return this.value
      }
    }
  }
</script>

<style scoped lang="scss">
    .service-village-select-container {
        width: 100%;
        display: flex;
        align-items: center;

        .title {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
            font-family: PingFangSC-Semibold, PingFang SC;
        }

        .disable-div {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .select-show {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .select-show-text {
                flex: 1;
                font-size: 14px;
                color: #333;
                font-family: PingFangSC-Regular, PingFang SC;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .placeholder {
                color: #939393;
            }

            img {
                width: 20px;
                height: 20px;
                margin-left: 5px;
            }
        }
    }
</style>