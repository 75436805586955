<template>
    <div class="select-container">
        <div class="title">
            {{title}}
        </div>
        <div class="select-show" @click="show = true">
            <div class="select-show-text" :class="[value == 0 ? 'placeholder' : '']">{{showText}}</div>
            <img src="https://api.mengjingloulan.com/storage/wechat/services/down-gray.png"/>
        </div>
        <div class="disable-div" v-if="disabled"></div>

        <nut-popup v-model="show" get-container="body" position="bottom" :round="true" :closeable="true">
            <div class="popup-window">
                <div class="select-line" v-for="(item,index) in finalOptions" @click="setChildrenRadioClick(index)">
                    <rsck-radio :ref="'radio_'+index" class="radio" @onChooseClick="chooseItem(item)"
                                :checked="item.value == value">
                        {{item.label}}
                    </rsck-radio>
                </div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  import RsckRadio from '@/components/common/rsck-radio'

  export default {
    name: 'services-select',
    components: {RsckRadio},
    props: {
      title: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      defaultValue: {
        type: [String, Number],
        default: 0
      },
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: ''
      }
    },
    mounted () {
      this.value = parseInt(this.$props.defaultValue)
      if (this.$props.placeholder.length > 0) {
        this.showText = this.$props.placeholder
      }
      let options = this.$props.options
      options.forEach(option => {
        option.value = parseInt(option.value)
        if (this.value == option.value) {
          this.showText = option.label
        }
        this.finalOptions.push(option)
      })
    },
    data () {
      return {
        show: false,
        value: 0,
        showText: '',
        finalOptions: []
      }
    },
    methods: {
      chooseItem (item) {
        let value = item.value
        let text = item.label
        if (this.value == value) {
          this.value = 0
          this.showText = this.$props.placeholder
        } else {
          this.value = value
          this.showText = text
        }

        this.$emit('onSelect', {name: this.$props.name, value: this.value})

        this.show = false
      },
      setChildrenRadioClick (index) {
        this.$refs['radio_' + index][0].choose()
      },
      getValue () {
        return this.value
      }
    }
  }
</script>

<style scoped lang="scss">
    .select-container {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .title {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
            font-family: PingFangSC-Semibold, PingFang SC;
        }

        .disable-div {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .select-show {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .select-show-text {
                flex: 1;
                font-size: 14px;
                color: #333;
                font-family: PingFangSC-Regular, PingFang SC;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .placeholder {
                color: #939393;
            }

            img {
                width: 20px;
                height: 20px;
                margin-left: 5px;
            }
        }
    }

    .popup-window {
        min-height: 20px;
        max-height: 60%;
        background: #fff;
        padding: 30px 30px 30px 30px;
        display: flex;
        flex-wrap: wrap;

        .select-line {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .radio {
                font-size: 14px;
                color: #939393;
            }
        }
    }
</style>