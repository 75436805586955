<template>
    <rsck-page background="#fff" v-if="showResult" class="page-container">
        <rsck-refresh background="#fff" ref="refresh" @onPullDown="onPullDown"
                      :height="scrollHeight" :lock-pull-up="true">
            <div v-if="pageSetting.status == 2" class="examine-failed-reason">提示：{{statusText}}</div>
            <template v-for="(item,index) in pageSetting.drawer">
                <services-textarea
                        v-if="item.type == 'textarea'"
                        :title="item.label"
                        :default-value="item.value"
                        :ref="item.field_name"
                        :placeholder="item.placeholder"
                        :max-number="item.max"
                        :disabled="disabled"
                        :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                        @onTextareaBlur="onTextareaBlur"
                />
                <service-select
                        v-if="item.type == 'select'"
                        :title="item.label"
                        :ref="item.field_name"
                        :options="item.options"
                        :default-value="item.value"
                        :disabled="disabled"
                        :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                        :placeholder="item.placeholder"
                        @onSelect="onSelect"
                />
                <services-village-select
                        v-if="item.type == 'village_select'"
                        :title="item.label"
                        :ref="item.field_name"
                        :options="item.options"
                        :placeholder="item.placeholder"
                        :default-value="item.value"
                        :disabled="disabled"
                        :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                        @onChooseVillage="onChooseVillage"
                />
                <services-input
                        v-if="item.type == 'text'"
                        :title="item.label"
                        :unit="item.unit"
                        :ref="item.field_name"
                        :placeholder="item.placeholder"
                        :default-value="item.value"
                        :disabled="disabled || item.disabled"
                        :name="item.field_name"
                        :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                        @onInputBlur="onInputBlur"
                />
                <services-upload
                        v-if="item.type == 'upload'"
                        :title="item.label"
                        :placeholder="item.placeholder"
                        :max="item.max"
                        :ref="item.field_name"
                        :img-list="item.value"
                        :disabled="disabled"
                        :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                        :can-remove="!disabled || pageSetting.status == 2"
                        @onFileUpload="onFileUpload"

                />
                <div class="border-box" v-if="item.bottomBorder"></div>
            </template>

            <div class="submit-button-2" v-if="pageSetting.status == 2">
                <div class="remove" @click="remove">{{listTypeId == 1 ? '删除商品' : '删除服务'}}</div>
                <div class="button" @click="saveUpdate">{{pageSetting.button_text || '确定'}}</div>
            </div>

            <div class="submit-button-1" v-if="pageSetting.status == 1">
                <template v-if="pageSetting.put_on == 0">
                    <!--下架状态-->
                    <div class="remove" @click="remove">{{listTypeId == 1 ? '删除商品' : '删除服务'}}</div>
                    <template v-if="isEdit">
                        <div class="button" @click="saveUpdate">确定</div>
                    </template>
                    <template v-if="!isEdit">
                        <div class="button" @click="setOnOrOff">上架</div>
                    </template>
                </template>
                <template v-if="pageSetting.put_on == 1">
                    <!--上架状态-->
                    <div class="button2" @click="setOnOrOff">下架</div>
                </template>
            </div>

        </rsck-refresh>

        <rsck-loading ref="loading" text="数据保存中"></rsck-loading>

        <service-examine-popup ref="examine" :title="propTitle" :text="statusText"
                               :status="pageSetting.status"></service-examine-popup>
    </rsck-page>
</template>

<script>
  import RsckPage from '@/components/common/rsck-page'
  import RsckRefresh from '@/components/common/rsck-refresh'
  import ServicesTextarea from '@/pages/services/components/services-textarea'
  import ServiceSelect from '@/pages/services/components/services-select'
  import ServicesVillageSelect from '@/pages/services/components/services-village-select'
  import ServicesInput from '@/pages/services/components/services-input'
  import ServicesUpload from '@/pages/services/components/services-upload'
  import RsckLoading from '@/components/common/rsck-loading'
  import ServiceExaminePopup from '@/pages/services/components/service-examine-popup'

  export default {
    name: 'goods-info',
    components: {
      ServiceExaminePopup,
      RsckLoading,
      ServicesUpload,
      ServicesInput, ServicesVillageSelect, ServiceSelect, ServicesTextarea, RsckRefresh, RsckPage
    },
    mounted () {
      document.title = '商品详情'
      let query = this.$route.query
      this.listTypeId = query?.listTypeId || 1
      this.id = query?.id || 0
      this.init()
    },
    data () {
      return {
        listTypeId: 1,
        id: 0,
        pageSetting: [],
        showResult: false,
        scrollHeight: window.innerHeight,
        disabled: false,
        statusText: '',
        propTitle: '',
        canContinue: true,
        watchEdit: false,
        isEdit: false
      }
    },

    methods: {
      watchIsEdit () {
        let setting = []
        let drawer = this.pageSetting.drawer
        let isEdit = false
        drawer.forEach(item => {
          setting.push({
            required: item.required,
            name: item.field_name,
            message: item.placeholder ? item.placeholder : (item.label) + '不能为空'
          })
        })
        let params = {}
        setting.forEach(item => {
          let value
          let uploadVal
          if (typeof this.$refs[item.name] != 'undefined') {
            value = this.$refs[item.name][0].getValue()
            drawer.forEach(val => {
              if (val.field_name == item.name) {
                if (val.type == 'upload') {
                  uploadVal = []
                  val.value.forEach(i => {
                    uploadVal.push(i.id)
                  })
                  if (uploadVal.join(',') != value) {
                    isEdit = true
                  }
                } else {
                  if (val.value != value) {
                    isEdit = true
                  }
                }
                console.log('val.value', val.value)
                console.log('val', val)
                console.log('value', value)

              }
            })
          }
        })
        this.isEdit = isEdit
      },
      init () {
        //读取页面的配置
        this.disabled = false
        this.getPageSetting()
      },
      onPullDown () {
        this.getPageSetting()
        setTimeout(() => {
          this.$refs.refresh.endPullDown()
          this.$refs.refresh.endPullUp()
        }, 300)
      },
      getPageSetting () {
        this.$api.services.getEditPageSetting({
          list_type_id: this.listTypeId,
          id: this.id
        }).then(response => {
          document.title = response.data.title
          this.pageSetting = response.data
          //status 表示状态
          // 目前暂定
          // 0 表示 审核中
          // 1 通过
          // 2 未通过

          let status = this.pageSetting.status
          if (status == 0) {
            if (this.listTypeId == 1) {
              this.statusText = '您上传的商品正在审核中，请您耐心等待'
            } else {
              this.statusText = '您上传的服务正在审核中，请您耐心等待'
            }
            this.propTitle = '审核中'
            this.disabled = true
          } else if (status == 2) {
            this.statusText = this.pageSetting.reasons_for_failure
            this.propTitle = '审核未通过'
          } else if (status == 1) {
            if (this.pageSetting.put_on == 0) {
              this.watchEdit = true
            }
          }

          //根据状态，弹出对应的提示框
          if (status == 0) {
            this.disabled = true
            setTimeout(() => {
              this.$refs.examine.show()
            }, 200)
          } else if (status == 2) {
            setTimeout(() => {
              this.$refs.examine.show()
            }, 200)
          } else {

          }

          this.showResult = true
        })
      },
      onChooseVillage (data) {
        if (this.watchEdit) {
          this.watchIsEdit()
        }
      },
      onFileUpload () {
        if (this.watchEdit) {
          this.watchIsEdit()
        }
      },
      onSelect () {
        if (this.watchEdit) {
          this.watchIsEdit()
        }
      },
      onTextareaBlur () {
        if (this.watchEdit) {
          this.watchIsEdit()
        }
      },
      onInputBlur (e) {
        let data = e
        let name = data.name
        let value = data.value

        if (name == 'price') {
          //请求接口，计算结算价格
          this.$api.services.getRebatePrice({price: value}).then(response => {
            let rebate_price = response.data
            if (typeof this.$refs.rebate_price != 'undefined') {
              this.$refs.rebate_price[0].setValue(rebate_price)
            }
            this.$forceUpdate()
          })
        }
        if (this.watchEdit) {
          this.watchIsEdit()
        }
      },
      remove () {
        if (this.canContinue) {
          this.canContinue = false

          this.$refs.loading.show()
          this.$api.services.deleteGoods({id: this.id, list_type_id: this.listTypeId}).then(response => {
            this.$refs.loading.hide()
            this.$toast.success('删除成功')
            //todo 删除成功后，跳转回列表页面

            let query = {
              listTypeId: this.listTypeId,
              isRefresh: 1
            }
            query = encodeURIComponent(JSON.stringify(query))
            this.$platform.wxsdk.wxRoute({
              type: 'redirectTo',
              url: '/web/services/my-services?query=' + query,
            })

          }).catch(error => {
            this.$toast.fail(error.message)
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      saveUpdate () {
        if (this.canContinue) {
          this.canContinue = false

          //更新信息
          let setting = []
          this.pageSetting.drawer.forEach(item => {
            setting.push({
              required: item.required,
              name: item.field_name,
              message: item.placeholder ? item.placeholder : (item.label) + '不能为空'
            })
          })
          let params = {}
          setting.forEach(item => {
            let value = this.$refs[item.name][0].getValue()
            if (item.required == true && !value) {
              this.$toast.warn(item.message)
              throw new Error(item.message)
              return
            }
            params[item.name] = value
          })

          params.list_type_id = this.listTypeId
          params.id = this.id

          this.$refs.loading.show()
          this.$api.services.saveUpdate(params).then(response => {
            console.log('保存的结果', response)
            this.$refs.loading.hide()
            this.$toast.success('保存成功')
            setTimeout(() => {
              this.init()
            }, 200)
          }).catch((error) => {
            this.$toast.fail(error.message)
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      setOnOrOff () {
        if (this.canContinue) {
          this.canContinue = false
          //设置上架或下架
          let putOnStatus = 0
          let putOnStatusText = '下架成功'
          if (this.pageSetting.put_on == 0) {
            putOnStatus = 1
            putOnStatusText = '上架成功'
          }
          this.$refs.loading.show()
          this.$api.services.setOnOrOff({
            id: this.id,
            list_type_id: this.listTypeId,
            status: putOnStatus
          }).then(response => {
            this.$refs.loading.hide()
            this.$toast.success(putOnStatusText)
            setTimeout(() => {
              this.init()
              this.canContinue = true
            }, 200)
          }).catch(error => {
            this.$toast.fail(error.message)
            this.canContinue = true
          })

        }

      }
    }
  }
</script>

<style scoped lang="scss">
    .page-container {
        padding: 0 15px;
    }

    .border-box {
        border-bottom: #EBEBEB solid 1px;
        padding-top: 20px;
    }

    .submit-button-2 {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-around;

        .remove {
            width: 168px;
            height: 44px;
            background: #FFE6D5;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            color: #F46A17;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .button {
            width: 168px;
            background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
            height: 44px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
        }
    }

    .submit-button-1 {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-around;

        .remove {
            width: 168px;
            height: 44px;
            background: #FFE6D5;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            color: #F46A17;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .button {
            width: 168px;
            background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
            height: 44px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
        }

        .button2 {
            width: 100%;
            background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
            height: 44px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
        }
    }

    .space-line {
        width: 100%;
        height: 20px;
    }

    .examine-failed-reason {
        color: #DF8621;
        font-size: 12px;
        line-height: 20px;
        background: #FFF9EC;
        padding: 9px 10px;
    }
</style>