<template>
  <div class="success-upload-container">
    <div class="title">
      <div class="images">
        <img
          src="https://api.mengjingloulan.com/storage/wechat/services/camera.png"
        />
      </div>
      {{ title }}
    </div>
    <div class="placeholder">{{ placeholder }}</div>
    <div class="upload-images">
      <upload-img-v1
        :max="max"
        :autoUpload="true"
        :imgList.sync="imageList"
        apiUrl="https://community-mall.mengjingloulan.com/file-upload"
        :can-remove="canRemove"
        @onFileUpload="onFileUpload"
      />
    </div>
    <div class="disabled-div" v-if="disabled"></div>
  </div>
</template>

<script>
import UploadImgV1 from "@/template/community/uploadImg/uploadImgV1";

export default {
  name: "services-upload",
  components: { UploadImgV1 },
  props: {
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    max: {
      type: [String, Number],
      default: 5,
    },
    imgList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageList: [],
    };
  },
  mounted() {
    this.imageList = JSON.parse(JSON.stringify(this.$props.imgList));
  },
  methods: {
    getValue() {
      let imageList = [];
      this.imageList.forEach((item) => {
        imageList.push(item.id);
      });
      return imageList.join(",");
    },
    onFileUpload() {
      this.$emit("onFileUpload");
    },
  },
};
</script>

<style scoped lang="scss">
.success-upload-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .disabled-div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .title {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    margin-bottom: 10px;

    .images {
      width: 16px;
      margin-right: 3px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .placeholder {
    width: 100%;
    color: #939393;
    font-size: 12px;
  }

  .upload-images {
    width: 100%;
    margin-top: 10px;
  }
}
</style>