<template>
    <nut-popup v-model="visible" get-container="body" :closeable="false"
               class="services-examine-popup-container">
        <div class="inner-widow">
            <div class="title">
                {{title}}
            </div>
            <div class="image">
                <img :src="imageSrc"/>
            </div>
            <div class="text">
                {{text}}
            </div>
            <div class="button-container">
                <div class="button" @click="visible = false">确定</div>
            </div>
        </div>
    </nut-popup>
</template>

<script>
  export default {
    name: 'service-examine-popup',
    props: {
      title: {
        type: String,
        default: ''
      },
      status: {
        type: [String, Number],
        default: 0
      },
      text: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        visible: false,
      }
    },
    computed: {
      imageSrc(){
        let status = this.$props.status
        if(status == 0){
          return 'https://api.mengjingloulan.com/storage/wechat/services/search.png'
        }else if(status == 2){
          return 'https://api.mengjingloulan.com/storage/wechat/services/failed.png'
        }
      }
    },
    methods: {
      show () {
        this.visible = true
      },
      hide () {
        this.visible = false
      }
    }
  }
</script>

<style scoped lang="scss">
    .services-examine-popup-container {
        width: 291px;
        padding: 27px 22px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        .inner-widow {

            display: flex;
            flex-wrap: wrap;

            .title {
                width: 100%;
                font-size: 16px;
                color: #333333;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                margin-bottom: 26px;
            }

            .image {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 14px;

                img {
                    width: 109px;
                    height: 109px;
                }
            }

            .text {
                width: 100%;
                color: #3C3C3C;
                font-size: 14px;
                margin-bottom: 57px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .button-container {
                width: 100%;

                .button {
                    width: 100%;
                    height: 44px;
                    background: linear-gradient(125deg, #FAB476 0%, #EB6100 100%, #EB6100 100%);
                    border-radius: 6px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
</style>